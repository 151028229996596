(function ( ) {

    let defaults = {
        selectors: {
            email: ".epost",
            vcard: ".vcard"
        }
    }

	    // Email adresses

	let getEmail = function(text) {

    	let email = {
        	label: text,
        	href: ""
    	};

    	if (typeof text !== "string" || text === "") {
        	return email;
    	}

		email.label = email.label.replace(/ ?\(at\) ?/, "@");
		email.label = email.label.replace(/ ?\(punkt\) ?/, ".");

		email.href = email.label;

            // Email address in brackets

		if (email.label.search(/\[.*\]/) !== -1) {

    		let results = email.label.match(/(.*?) ?\[(.*)\]/);

    		email.href = results[2] ? results[2] : email.label;
    		email.label = results[1] ? results[1] : email.label;

        }

        email.href = "mailto:" + email.href;

		return email;

	}

    let elements = document.querySelectorAll(defaults.selectors.email);

    for (let i = 0; i < elements.length; i++) {

        let element = elements[i];

        let email = getEmail(element.innerText);

        element.innerText = email.label;

        if (email.href) {

            let link =  document.createElement('a');

            link.setAttribute("href", email.href);
            link.innerText = email.label;

            element.innerText = "";
            element.appendChild(link);

        }

    }

}());
