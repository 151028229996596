export default (function (){

    const defaults = {

    };

    function init() {
        document.documentElement.style.setProperty('--scrollbar-width', getScrollbarWidth() + 'px');
    }

    function getScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    return {
        init: init
    }

})();
