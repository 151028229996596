export default (function (){

    const defaults = {
        selectors: {
            containers: ".mainNav",
            triggers: ".mainNav-trigger",
        },
    };

    function init() {

        const triggers = document.querySelectorAll(defaults.selectors.triggers);

        for (var i = 0; i < triggers.length; i++) {

            const trigger = triggers[i];

            trigger.addEventListener("click", (event) => {
                event.preventDefault();
                toggle();
            });

        }

    }

    function toggle(showNavigation) {

        const containers = document.querySelectorAll(defaults.selectors.containers);

        for (var i = 0; i < containers.length; i++) {

            const container = containers[i];

            if (typeof showNavigation !== "boolean") {
                showNavigation = container.getAttribute("data-show-nav") !== "true";
            }

            if (showNavigation) {
                container.setAttribute("data-show-nav", "true");
            } else {
                container.setAttribute("data-show-nav", "false");
            }

        }

    }

    return {
        init: init
    }

})();
